import styled from '@emotion/styled';
import rhythm from '../utils/rhythm';
import colors from '../styles/modules/colors.module.scss';

const Styles = styled.main({
  header: {
    position: 'relative',
    width: '100vw',
    height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    div: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'calc(-.095 * 50vw)',
    },

    svg: {
      cursor: 'default',
    },
  },

  h1: {
    marginTop: `calc(-.095 * 50vw + ${rhythm(1)})`,
    marginBottom: 0,
    paddingLeft: `${rhythm(1)}`,
    paddingRight: `${rhythm(1)}`,
    color: colors.primary,
  },

  '.case-study': {
    svg: {
      height: `${rhythm(5)}`,
      maxWidth: `${rhythm(5)}`,
    },

    '.h4': {
      marginTop: `${rhythm(1)}`,
      marginBottom: `${rhythm(1 / 2)}`,
    },
  },
});

export default Styles;
