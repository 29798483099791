import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import ContentCol from '../components/contentCol/contentCol';
import ChatBanner from '../components/chatBanner/chatBanner';
import Styles from './index.styles';

import { ReactComponent as GenerativConcerns } from '../images/concerns.svg';
import { ReactComponent as ClientLogoWaxInsurance } from '../images/clients/client-logo-wax.svg';
import { ReactComponent as ClientLogoMuchSmarter } from '../images/clients/client-logo-ms.svg';
import { ReactComponent as ClientLogoSproutHealth } from '../images/clients/client-logo-shg.svg';

const IndexPage = ({ data }) => {
  // Lottie setup
  const lottieOptions = {
    autoplay: true,
    loop: false,
    animationData: require('../../static/logo.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Layout>
      <Seo
        title="Home"
        description="A full-service agency for startups, entrepreneurs, and growing companies. We provide nimble, impactful execution on fundraising, product & business development, design, code, marketing, and strategic advisement."
      />
      <Styles>
        <header>
          <div>
            <Lottie
              options={lottieOptions}
              height={'calc(.38 * 50vw)'}
              width={'50vw'}
              title={'Generativ'}
            />
            <h1>The Startup Agency</h1>
          </div>
        </header>

        <Container>
          <section className="mb-5">
            <Row className="align-items-center justify-content-center px-lg-4 px-xl-5">
              <Col className="d-none d-md-block">
                <GenerativConcerns />
              </Col>
              <Col xs={11} sm={8} className="text-center text-md-left">
                <p className="d-block mb-4">
                  {/*Generativ is a full-service agency for startups, entrepreneurs, and growing companies. We provide nimble, impactful execution on fundraising, product &amp; business development, design, code, marketing, and strategic advisement.*/}
                  Generativ offers a full suite of services to our clients. We provide nimble, impactful execution on product &amp; business development, marketing, fundraising; also design, code, financial and strategic advisement.
                </p>
                <Link to="/process/" className="btn btn-primary">
                  Our Process
                </Link>
              </Col>
            </Row>
          </section>

          <hr />

          <section className="my-5 text-center text-md-left">
            <Row className="my-5">
              <Col xs={12} md={4} className="mb-4">
                <Link to="/work/wax-insurance/" className="case-study">
                  <ClientLogoWaxInsurance />
                  <h3 className='h4'>Wax Insurance</h3>
                  <p>
                    Winning the first-to-market race with a product that
                    delivers on luxury expectations
                  </p>
                </Link>
              </Col>

              <Col xs={12} md={4} className="mb-4">
                <Link to="/work/much-smarter/" className="case-study">
                  <ClientLogoMuchSmarter />
                  <h3 className='h4'>Much Smarter</h3>
                  <p>
                    Helping an established analog company scale with
                    technology and enter new markets
                  </p>
                </Link>
              </Col>

              <Col xs={12} md={4}>
                <Link to="/work/sprout-health" className="case-study">
                  <ClientLogoSproutHealth />
                  <h3 className='h4'>Sprout Health</h3>
                  <p>
                    Rebuilding an established property to outrank larger brands
                    in a fiercely competitive market
                  </p>
                </Link>
              </Col>
            </Row>

            <Row className="testimonial-row">
              <Col>
                <Img
                  fluid={data.calvin.childImageSharp.fluid}
                  alt="Calvin, Founder of Wax Insurance"
                  className="rounded-circle"
                />
              </Col>
              <Col>
                <blockquote>
                  <p>
                    <span>&ldquo;</span>As an entrepreneur and founder I've
                    always found it difficult to entrust my vision to others.
                    When Generativ takes on a project, they're not just a
                    rockstar dev shop, they're partners and founders. Generativ
                    brought our WAX dream to life, and we are proud to work with
                    the best.
                  </p>
                  <footer>
                    Calvin, Founder of{' '}
                    <a
                      href="https://wax.insure/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WAX
                    </a>
                  </footer>
                </blockquote>
              </Col>
            </Row>
          </section>

          <hr />

          <section className="my-5">
            <Row className="justify-content-center text-center">
              <ContentCol>
                <h2>
                  We believe technology can build a better, more inclusive world
                </h2>
                <p className="d-block mb-4">
                  So we take on pro-bono projects meant to arm talented
                  activists and non-profit organizations with the visibility,
                  tech stack, and operational workflow they need to make the
                  biggest difference they can.
                </p>
                <Link to="/social-impact/" className="btn btn-primary">
                  Get Involved
                </Link>
              </ContentCol>
            </Row>
          </section>
        </Container>
      </Styles>

      <ChatBanner />
    </Layout>
  );
};

export const query = graphql`
  query {
    calvin: file(relativePath: { eq: "founders/founder-calvin.jpeg" }) {
      ...imageFluidSmallGrayscale
    }
  }
`;

export default IndexPage;
